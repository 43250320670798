.layout {
  height: 100vh;
}

.header {
  background: #001529;
  color: white;
  text-align: center;
  padding: -10px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.content {
  padding: 20px;
  overflow: 'initial';
}

.left-column,
.right-column {
  max-height: calc(100vh - 64px - 48px);
  overflow-y: auto;
  padding: 16px;
  background: white;
}


img {
  margin-top: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}